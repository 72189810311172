@tailwind components;
@tailwind utilities;
@tailwind base;

@layer base {
  .label {
    color: #777777;
    font-size: larger;
    margin-bottom: 10px;
  }

  .button__ {
    align-items: center;
    justify-content: center;
    display: flex;
    color: #ffffff;
    font-size: 18px;
    background: #58cc02;
    border: none !important;
    padding: 15px 30px;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 0 #58a700;
    border-radius: 12px;
    cursor: pointer;
  }

  .button__:active {
    box-shadow: none;
    transform: translate(0, 4px);
  }

  .input_SignIn::placeholder {
    font-size: 20px !important;
  }

  .buttonUpdateArtikel {
    --bg: #ffffff;
    --bg2: #86bb65;
    --hover-bg: #ffffff;
    --hover-text: #86bb65;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid var(--bg);
    border-radius: 10px;
    padding: 0.8em 2em;
    background: var(--bg2);
    margin: 20px 20px 20px 20px;
    transition: 0.2s;
  }

  .buttonUpdateArtikel:hover {
    color: var(--hover-bg);
    transform: translate(-0.25rem, -0.25rem);
    background: var(--hover-text);
    box-shadow: 0.25rem 0.25rem var(--bg2);
  }

  .buttonDeleteArtikel {
    --bg: #ffffff;
    --bg2: #b41318;
    --hover-bg: #ffffff;
    --hover-text: #b41318;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid var(--bg);
    border-radius: 10px;
    padding: 0.8em 2em;
    background: var(--bg2);
    margin: 20px 20px 20px 20px;
    transition: 0.2s;
  }

  .buttonDeleteArtikel:hover {
    color: var(--hover-bg);
    transform: translate(-0.25rem, -0.25rem);
    background: var(--hover-text);
    box-shadow: 0.25rem 0.25rem var(--bg2);
  }

  .button_kirim {
    --bg: #ffb4b4;
    --hover-bg: #ffffff;
    --hover-text: #ffb4b4;
    color: #ffffff;
    font-weight: bold;
    width: 40%;
    border: 1px solid var(--bg);
    border-radius: 10px;
    padding: 0.8em 2em;
    background: var(--bg);
    transition: 0.2s;
  }

  .button_kirim:hover {
    color: var(--hover-text);
    transform: translate(-0.25rem, -0.25rem);
    background: var(--hover-bg);
    box-shadow: 0.25rem 0.25rem var(--bg);
  }

  .button_kirim:active {
    transform: translate(0);
    box-shadow: none;
    border: 1px solid var(--hover-bg);
  }
}

@layer components {
  .sidebarlink {
    @apply flex items-center py-3  px-6 relative cursor-pointer;
  }

  .icon {
    @apply text-2xl;
  }

  .sidebarlink.active::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    background-color: #ffb4b4;
    color: "#FFFFFF";
  }

  .sidebar-icon {
    @apply mr-2 text-sm inline-block;
  }
}
