.button_kirim {
    color: #fff;
    height: 50px;
    width: 100px;
    border: 2px solid #24b4fb;
    background-color: #24b4fb;
    border-radius: 0.9em;
    padding: 0.8em 1.2em 0.8em 1em;
    transition: all ease-in-out 0.2s;
    font-size: 14px;
}

.button_kirim span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
}

.button_kirim:hover {
    background-color: #0071e2;
    color: #fff!important;
}