.button3 {
  --bg: #ffffff;
  --hover-bg: #ffb4b4;
  --hover-text: #ffffff;
  color: #b41318;
  font-weight: bold;
  width: 20%;
  border: 1px solid var(--bg);
  border-radius: 10px;
  padding: 0.8em 2em;
  background: var(--bg);
  transition: 0.2s;
}

.button3:hover {
  color: var(--hover-text);
  transform: translate(-0.25rem, -0.25rem);
  background: var(--hover-bg);
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button3:active {
  transform: translate(0);
  box-shadow: none;
  border: 1px solid var(--hover-bg);
}

.button2 {
  --bg: #ffffff;
  --bg2: #ffb4b4;
  --hover-bg: #ffffff;
  --hover-text: #ffb4b4;
  color: #b41318;
  font-weight: bold;
  width: 100%;
  border: 1px solid var(--bg);
  border-radius: 10px;
  padding: 0.8em 2em;
  background: var(--bg);
  box-shadow: 0px 6px 0px 0px var(--bg2);
  margin: 20px 20px 20px 20px;
  transition: 0.2s;
}

.button2:hover {
  color: var(--hover-text);
  transform: translate(-0.25rem, -0.25rem);
  background: var(--hover-bg);
  box-shadow: 0px 6px 0px 0px var(--bg2);
}

.button2:active {
  transform: translate(0);
  box-shadow: none;
  background: var(--bg2);
  color: var(--bg);
}

.btnDetail {
  --bg: #ffffff;
  --bg2: #ffb4b4;
  --hover-bg: #ffffff;
  --hover-text: #ffb4b4;
  color: #ffffff;
  font-weight: bold;
  width: 30%;
  border: 1px solid var(--bg);
  border-radius: 10px;
  padding: 0.8em 2em;
  background: var(--bg2);
  margin: 20px 20px 20px 20px;
  transition: 0.2s;
}

.btnDetail:hover {
  color: var(--hover-bg);
  transform: translate(-0.25rem, -0.25rem);
  background: var(--hover-text);
  box-shadow: 0.25rem 0.25rem var(--bg2);
}

.buttonUpdate {
  --bg: #ffffff;
  --bg2: #86bb65;
  --hover-bg: #ffffff;
  --hover-text: #86bb65;
  color: #ffffff;
  font-weight: bold;
  width: 40%;
  border: 1px solid var(--bg);
  border-radius: 10px;
  padding: 0.8em 2em;
  background: var(--bg2);
  margin: 20px 20px 20px 20px;
  transition: 0.2s;
}

.buttonUpdate:hover {
  color: var(--hover-bg);
  transform: translate(-0.25rem, -0.25rem);
  background: var(--hover-text);
  box-shadow: 0.25rem 0.25rem var(--bg2);
}

.buttonDelete {
  --bg: #ffffff;
  --bg2: #b41318;
  --hover-bg: #ffffff;
  --hover-text: #b41318;
  color: #ffffff;
  font-weight: bold;
  width: 40%;
  border: 1px solid var(--bg);
  border-radius: 10px;
  padding: 0.8em 2em;
  background: var(--bg2);
  margin: 20px 20px 20px 20px;
  transition: 0.2s;
}

.buttonDelete:hover {
  color: var(--hover-bg);
  transform: translate(-0.25rem, -0.25rem);
  background: var(--hover-text);
  box-shadow: 0.25rem 0.25rem var(--bg2);
}
