.button_detail {
    --bg: #F53232;
    --text-color: #fff;
    position: relative;
    width: 150px;
    border: none;
    background: var(--bg);
    color: var(--text-color);
    padding: 1em;
    font-weight: bold;
    font-size:12px;
    text-transform: uppercase;
    transition: 0.2s;
    border-radius: 5px;
    opacity: 0.8;
    letter-spacing: 1px;
    margin: 0 10px 0 10px;
    box-shadow: #9E1E1E 0px 7px 2px, #000 0px 8px 5px;
}

.button_detail:hover {
    opacity: 1;
}

.button_detail:active {
    top: 4px;
    box-shadow: #c0392b 0px 3px 2px, #000 0px 3px 5px;
}