.table-dashboard {
    height: 500px;
    width: 100%;
}

.button_dashboard {
    font-family: monospace;
    background-color: #f3f7fe;
    color: #3b82f6;
    border: none;
    border-radius: 8px;
    width: 100px;
    height: 45px;
    transition: .3s;
}

.button_dashboard:hover {
    background-color: #3b82f6;
    box-shadow: 0 0 0 5px #3b83f65f;
    color: #fff;
}

.button_dashboard a:hover {
    background-color: #3b82f6;
    box-shadow: 0 0 0 5px #3b83f65f;
    color: #fff;
}

.cssbuttons-io-button {
    background: #FF9999!important;
    color: white;
    font-family: inherit;
    padding: 0.35em;
    padding-left: 1.2em;
    font-size: 17px;
    font-weight: 500;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.6em -0.6em #ae4e4e;
    overflow: hidden;
    position: relative;
    height: 2.8em;
    padding-right: 3.3em;
}

.cssbuttons-io-button .icon {
    background: white!important;
    margin-left: 1em!important;
    position: absolute!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    height: 2.2em!important;
    width: 2.2em!important;
    border-radius: 0.7em!important;
    box-shadow: 0.1em 0.1em 0.6em 0.2em #f78989!important;
    right: 0.3em!important;
    transition: all 0.3s;
    font-size: medium;
}

.cssbuttons-io-button:hover .icon {
    width: calc(100% - 0.6em)!important;
}

.cssbuttons-io-button .icon svg {
    width: 1.1em!important;
    transition: transform 0.3s!important;
    color: #FF9999!important;
}

.cssbuttons-io-button:hover .icon svg {
    transform: translateX(0.1em)!important;
}

.cssbuttons-io-button:active .icon {
    transform: scale(0.95)!important;
}

.dashboard {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 20px;

    color: #B14444;
}