.number-statistic {
  font-size: 40px;
  font-weight: 700;
  margin: 6px 0 4px 0;
}

.title-statistic {
  font-size: 15px;
  font-weight: 500;
}

#main {
  transform: scale(0.8);
}

body {
  overflow-x: hidden;
}

/* .title-banner {
  background-color: aqua;
  width: 400px;
  height: 500px;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
} */
.banner {
  width: 900px;
}

.banner h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
  font-size: 32px;
  color: #b14444;
}

.description-banner {
  font-size: 16px;
  line-height: 10px;
  margin-bottom: 20px;
}

.container {
  margin: 20px 50px 35px 50px;
}

.container.h-100 {
  height: 100vh;
}

.title-header {
  font-size: 30px;
}

.full-screen-container {
  height: 100vh;
  width: 100vw;
}

.button {
  --bg: #ffffff;
  --hover-bg: #b14444;
  --hover-text: #ffffff;
  color: #b14444;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.8em 2em;
  background: var(--bg);
  transition: 0.2s;
}

.button:hover {
  color: var(--hover-text);
  transform: translate(-0.25rem, -0.25rem);
  background: var(--hover-bg);
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button:active {
  transform: translate(0);
  box-shadow: none;
}