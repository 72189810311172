.ant-input {
    border: 1px solid #e92323 !important;
    border-radius: 5px !important;
}

.button_reminder {
    width: 170px;
    height: 40px;
    border-radius: 12px;
    border: none;
    background-color: #F53232;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: .5s;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
    position: relative;
}

.IconContainer {
    position: absolute;
    left: -50px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    transition-duration: .5s;
}

.icon {
    border-radius: 1px;
}

.text_reminder {
    margin-top: 12px;
    height: 100%;
    width: fit-content;
    opacity: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    z-index: 1;
    transition-duration: .5s;
    font-size: 1.04em;
    font-weight: 600;
}

.button_reminder:hover .IconContainer {
    transform: translateX(58px);
    border-radius: 40px;
    transition-duration: .5s;
}

.button_reminder:hover .text_reminder {
    transform: translate(10px, 0px);
    transition-duration: .5s;
}

.button_reminder:active {
    transform: scale(0.95);
    transition-duration: .5s;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgb(255 0 0 / 45%)!important;
}

#btn-desa, [type="reset"]{
    background-color: rgb(255, 180, 180)!important;
}

.btn-desa {
    --bg: rgb(255, 180, 180);
    --hover-bg: #ff90e8;
    --hover-text: #000;
    color: #fff;
    border: 1px solid var(--bg);
    border-radius: 4px;
    padding: 0.8em 2em;
    background: var(--bg);
    transition: 0.2s;
}

.btn-desa:hover {
    color: var(--hover-text);
    transform: translate(-0.25rem, -0.25rem);
    background: var(--hover-bg);
    box-shadow: 0.25rem 0.25rem var(--bg);
}

a .btn-desa:hover {
    color: var(--hover-text);
    transform: translate(-0.25rem, -0.25rem);
    background: var(--hover-bg);
    box-shadow: 0.25rem 0.25rem var(--bg);
}

.btn-desa:active {
    transform: translate(0);
    box-shadow: none;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgb(0 0 0);
    width: 62px !important;
}