.label {
    color: #777777;
    font-size: larger;
    margin-bottom: 10px;
}


.button__ {
    align-items: center;
    justify-content: center;
    display: flex;
    color: #ffffff;
    font-size: 18px;
    background: #58cc02;
    border: none !important;
    padding: 15px 30px;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 0 #58a700;
    border-radius: 12px;
    cursor: pointer;
}

.button__:active {
    box-shadow: none;
    transform: translate(0, 4px);
}

.input_SignIn::placeholder {
    font-size: 20px!important;
}