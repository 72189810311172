.cta {
    margin-top: "20px";
    position: relative;
    top: 4;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
}

.cta:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #e3b1e7;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #234567;
}

.cta svg {
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #234567;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.cta:hover:before {
    width: 100%;
    background: #b1dae7;
}

.cta:hover svg {
    transform: translateX(0);
}

.cta:active {
    transform: scale(0.95);
}